import "./App.css";
import "./style.css";
import "./index.css";
import "./bootstrap.min.css";
import "./css/main.css"

const websiteConfig = {
    clickonflirt: {
        cssFile: "clickonflirt.css",
    },
    luvbinder: {
        cssFile: "luvbinder.css",
    },
    nextdatex: {
        cssFile: "nextdatex.css",
    },
    localdatex: {
        cssFile: "localdatex.css",
    },
    mycupidx: {
        cssFile: "mycupidx.css",
    },
};

const currentWebsite = process.env.REACT_APP_WEBSITE_ID;
if (websiteConfig[currentWebsite] && websiteConfig[currentWebsite].cssFile) {
    import(`./css/${websiteConfig[currentWebsite].cssFile}`);
}


